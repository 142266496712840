import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

import { Gallery } from "gatsby-theme-gallery";

const Gallery2019Page = () => (
  <Layout>
    <div id="wrapper">
      <ul>
        <li><Link to="/" className="button ">Nazad</Link></li>
      </ul>
      <div className="gallery">
        <Gallery />
      </div>
    </div>
  </Layout>
)

export default Gallery2019Page
